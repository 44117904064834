var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    ref: "autocompleteFieldProvider",
    attrs: {
      slim: "",
      name: _vm.name,
      vid: _vm.id,
      rules: _vm.rules,
      mode: _vm.mode ? _vm.mode : "eager",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return [
            _c(
              "v-autocomplete",
              _vm._b(
                {
                  ref: "autocompleteField",
                  attrs: {
                    "data-vv-validate-on": "change",
                    "error-messages": errors,
                    name: _vm.id,
                    value: _vm.value,
                    "item-text": _vm.itemText,
                    "sub-text": _vm.subText,
                    "no-data-text": "Não há itens",
                    disabled: _vm.disabled,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", $event)
                    },
                    click: function ($event) {
                      return _vm.$emit("click", $event)
                    },
                    keyup: function ($event) {
                      return _vm.$emit("keyup", { $event, id: _vm.id })
                    },
                    blur: function ($event) {
                      return _vm.$emit("blur", { $event, id: _vm.id })
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.itemText
                        ? {
                            key: "item",
                            fn: function (item) {
                              return [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-id": `${_vm.name.toLowerCase()}-${item.item[
                                        _vm.itemText
                                      ]
                                        .replaceAll(" ", "-")
                                        .toLowerCase()}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.item[_vm.itemText] ||
                                            item.item["label"]
                                        ) +
                                        " " +
                                        _vm._s(
                                          item.item[_vm.subText]
                                            ? `(${item.item[_vm.subText]})`
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                "v-autocomplete",
                _vm.$attrs,
                false
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }