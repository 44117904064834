<template>
  <validation-provider  ref="autocompleteFieldProvider" slim v-slot="{errors }" :name="name" :vid="id" :rules="rules" :mode="mode ? mode : 'eager'">
    <v-autocomplete
      data-vv-validate-on="change"
      v-bind="$attrs"
      :error-messages="errors"
      :name="id"
      :value="value"
      :item-text="itemText"
      :sub-text="subText"
      @input="$emit('input', $event)"
      @click="$emit('click', $event)"
      @keyup="$emit('keyup', { $event , id })"
      @blur="$emit('blur', { $event , id })"
      ref="autocompleteField"
      no-data-text="Não há itens"
      :disabled="disabled"
    >
    <template v-if="itemText" v-slot:item="item">
      <span :data-test-id="`${name.toLowerCase()}-${item.item[itemText].replaceAll(' ', '-').toLowerCase()}`" >
        {{ item.item[itemText] || item.item['label'] }}
        {{ item.item[subText] ? `(${item.item[subText]})` : "" }}
      </span>
    </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    itemText: {
      type: String,
    },
    subText: {
      type: String,
    },
    value: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    autoBlur: {
      type: Number,
    },
    mode: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      if (newValue && this.autoBlur) {
        this.blur(newValue);
      }
    },
  },
  methods: {
    async blur(value) {
      if (value.length === this.autoBlur) {
        const { valid } = await this.$refs.textFieldProvider.validate();
        if (!valid) return;
        this.$emit('blurEvent');
        this.$refs.textField.blur();
      }
    },
  },
};
</script>
